@import '../../assets/styles/utils/keyframes';
@import '../../assets/styles/utils/mixins';

.game {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  padding: 15px 0 30px;
  @include safeArea(15px, 30px);
  max-width: 100vw;
  overflow: hidden;
  position: relative;

  & > * {
    position: relative;
    z-index: 2;
  }

  &__back {
    --safe-area-inset-top: env(safe-area-inset-top);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    top: calc(15px + (var(--safe-area-inset-top)));
    left: 0;
    z-index: 7;
    background: var(--theme-background-dark);
    color: var(--theme-color-reverse);
    height: 40px;
    width: 60px;
    transform: translateX(-20px);
    transition: transform .3s ease-in-out;
    border-radius: 0 20px 20px 0;
    align-items: center;
    padding: 0 10px;

    &:hover {
      transform: translateX(0);
    }
  }

  &__footer {
    margin-top: auto;
  }

  &__content {
    flex-basis: 100%;
    height: 0;
  }

  &__background {
    position: absolute;
    left: 50%;
    bottom: -70px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    z-index: 1;
    opacity: .05;
    fill: var(--theme-background-dark);
    transition: fill .3s ease-in-out, opacity .3s ease-in-out;
    max-width: 180vw;

    &_blured {
      filter: blur(8px);
    }
  }

  &_appear {
    animation: shake 200ms ease-in-out;
    animation-iteration-count: 2;
    animation-delay: 200ms;
  }
}

.choice {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__hand {
    width: 70%;
    opacity: 0;
    transform-origin: center;
    animation: appearLeftDefault .3s ease-in-out forwards;
    display: flex;
    flex-direction: column;
    height: 50%;

    &:last-child {
      animation: appearRightDefault .3s ease-in-out forwards;
      margin-left: auto;
    }

    &_many {
      .choice {
        &__hand {
          &__img {
            flex: none;
            max-width: 100%;
            max-height: calc(50%);
          }
        }
      }
    }

    &__img {
      max-width: 100%;
      max-height: 100%;
      margin: auto 0;
    }
  }
}

@include breakpoint(medium) {
  .game {
    padding: 30px 0 50px;

    &__content {
      position: relative;
    }

    &__background {
      top: calc(50% - 40px);
    }
  }

  .choice {
    flex-direction: row;
    align-items: center;
    margin: 0;

    &__hand {
      width: 45%;
      height: 100%;
      animation: appearLeft .3s ease-in-out forwards;

      &:last-child {
        animation: appearRight .3s ease-in-out forwards;
      }

      &_many {
        animation: appearLeftDefault .3s ease-in-out forwards;
      }
    }
  }
}
