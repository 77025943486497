@import '../../assets/styles/utils/mixins';

.rgpd {
  padding: 80px 0;
  height: 100vh;
  overflow: auto;

  h1 {
    @include fontSize(h1);
    text-align: center;
    font-weight: 700;
  }

  h2 {
    @include fontSize(h2);
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  p {
    margin-bottom: 15px;
    @include fontSize();
  }

  ul {
    margin-bottom: 15px;
    @include fontSize();
    padding-left: 20px;
  }
}