@import '../../assets/styles/utils/mixins';

.redirect {
  background: var(--theme-color);
  color: var(--theme-color-reverse);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: center;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  padding: 40px 0 0;

  &__content {
    margin: auto 0 0;
  }

  &__img {
    margin: auto auto 0;
    max-width: 90%;
  }

  &__link {
    padding: 30px 0;
  }
}

@include breakpoint(medium) {
  .complete {
    padding: 40px 0 0;

    &__img {
      max-width: 40%;
    }
  }
}