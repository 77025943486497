@import 'src/assets/styles/utils/mixins';

.extra {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 30px 0;
  overflow: hidden;
  min-height: 100vh;
  min-height: 100 dvh;
  @include safeArea(30px, 30px);

  &__title {
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }

  &__choices {
    padding: 30px 0;
    margin: auto;

    &__or {
      padding: 30px 0;
      margin: 0;
      opacity: 1;
    }
  }
}

.item {
  cursor: pointer;
  text-align: center;

  &__img {
    max-height: 30vh;
    fill: var(--theme-border);
  }
}

@include breakpoint(small) {
  .extra {
    &__choices {
      &__or {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity .3s ease-in-out;
      }
    }
  }
}

@include breakpoint(large) {
  .extra {
    padding: 80px 0;
    height: 100vh;
    height: 100 dvh;

    &__title {
      &_hover {
        opacity: 0;
      }
    }

    &__choices {
      &__item {
        transition: width .3s ease-in-out, flex .3s ease-in-out;
      }

      &__or {
        &_disabled {
          opacity: 0;
        }
      }
    }
  }

  .item {
    &__link {
      margin: 30px 0 0;
    }

    &__img {
      max-height: 50vh;
      transition: transform .3s ease-in-out, fill .3s ease-in-out;
      transform-origin: bottom;
      transform: scale(1);
      fill: var(--theme-win);

      &.active {
        transform: scale(1.3);
        fill: var(--theme-link);
      }

      &.inactive {
        transform: scale(.8);
      }

      &_malus {
        fill: var(--theme-lose);

        &.active {
          fill: var(--theme-background-dark);
        }
      }
    }
  }
}