:root {
  --theme-background: #FAE9E7;
  --theme-background-dark: #F37E72;
  --theme-border: #F5DFDC;
  --theme-link: #70ACFB;
  --theme-link-dark: #5B94F0;
  --theme-color: #39003C;
  --theme-color-light: #521159;
  --theme-color-reverse: var(--theme-background);
  --theme-text-opacity: .8;
  --theme-egal: #754D77;
  --theme-win: #4A3476;
  --theme-lose: #72264C;

  --theme-success: #7AB999;

  --theme-fontH1: clamp(40px, 3.4vw, 55px);
  --theme-fontH2: clamp(25px, 2.1vw, 35px);
  --theme-fontBig: clamp(20px, 1.7vw, 30px);
  --theme-fontSmall: clamp(16px, 1.2vw, 20px);
  --theme-fontNormal: clamp(18px, 1.35vw, 22px);
  --theme-fontCgv: clamp(10px, .88vw, 14px);
}