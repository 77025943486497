@import '../../assets/styles/utils/mixins';

.mode {
  width: 100%;
  text-align: center;
  overflow: auto;
  height:100vh;
  height:100dvh;

  &__container {
    padding: 30px 0;
    @include safeArea(30px, 30px);
    overflow: hidden;
    min-height: 100vh;
    min-height: 100 dvh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    opacity: 1;
  }

  &__choices {
    padding: 30px 0;
    margin: auto;

    &__or {
      padding: 30px 0;
      margin: 0;
      opacity: 1;
    }
  }
}

.item {
  cursor: pointer;
  text-align: center;

  &__img {
    max-height: 30vh;
    fill: var(--theme-border);
  }
}

@include breakpoint(small) {
  .mode {
    &__choices {
      &__or {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@include breakpoint(medium) {
  .mode {
    padding: 80px 0;
    height: 100vh;
    height: 100 dvh;

    &__title {
      transition: opacity .3s ease-in-out;

      &_hover {
        opacity: 0;
      }
    }

    &__choices {
      &__or {
        transition: opacity .3s ease-in-out;

        &_disabled {
          opacity: 0;
        }
      }

      &__item {
        transition: width .3s ease-in-out, flex .3s ease-in-out;
      }
    }
  }

  .item {
    &__link {
      margin: 30px 0 0;
    }

    &__img {
      max-height: 50vh;
      transition: transform .3s ease-in-out, fill .3s ease-in-out;
      transform-origin: bottom;
      transform: scale(1);

      &.active {
        transform: scale(1.3);
        fill: var(--theme-link)
      }

      &.inactive {
        transform: scale(.8);
      }
    }
  }
}