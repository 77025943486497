@import '../../../assets/styles/utils/mixins';

.waiting {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: center;
  height: 100vh;
  height: 100svh;
  overflow: hidden;

  &__content {
    margin: auto;
    padding: 30px 0 ;
  }

  &__timer {
    font-weight: 700;
    color: var(--theme-link);
  }

  &__text {
    margin-top: 20px;
    opacity: .7
  }

  &__picture {
    margin-top: auto;
    width: 100%;
  }
}

@include breakpoint(medium) {
  .waiting {
    &__content {
      margin: auto;
      padding: 50px 0 ;
    }

    &__picture {
      width: 90%;
      flex: 1;
      max-height: 60vh;
      display: flex;
      overflow: hidden;

      &__img {
        margin-top: auto;
        flex: none;
        height: auto;
        max-height: 100%;
      }
    }
  }
}