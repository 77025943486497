@import 'src/assets/styles/utils/keyframes';

.timer {
  color: var(--theme-link);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12vw;
  font-weight: 900;
  animation: counter 900ms ease-in-out;
}