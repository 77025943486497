@import '../../assets/styles/utils/keyframes';
@import '../../assets/styles/utils/mixins';

.round {
  text-align: center;

  &__picture {
    display: block;
    margin: auto auto -8px;
    position: relative;
  }

  &__number {
    display: inline-block;
    background: var(--theme-color);
    color: var(--theme-color-reverse);
    border-radius: 50px;
    padding: 8px 30px 5px;
    @include fontSize(h2);

    &_online {
      background: var(--theme-color-reverse);
      color: var(--theme-color);
    }
  }
}

.player {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__img {
    width: 50px;
    height: auto;

    &_online {
      width: 80px;
    }
  }

  &__score {
    @include fontSize(h1);
    margin: auto;
    padding: 0 10px 0 20px;

    &:first-child {
      margin: auto;
      padding: 0 20px 0 10px;
    }
  }
}

@include breakpoint(medium) {
  .player {
    &__score {
      padding: 0 10px;
      margin: auto auto auto 60px;

      &:first-child {
        padding: 0 10px;
        margin: auto 60px auto auto;
      }
    }
  }
}