@import '../../assets/styles/utils/mixins';

.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-origin: center top;
  animation: appear .3s ease-in-out forwards;
  padding: 0 15px 40px;

  &_win {
    & + svg {
      opacity: .2;
      fill: var(--theme-link);
    }
  }

  &_egal {
    & + svg {
      opacity: .2;
      fill: #fff;
    }
  }

  &_lose {
    & + svg {
      opacity: .2;
      fill: var(--theme-background-dark);
    }
  }

  &_online {
    &.result {
      &_win {
        & + svg {
          opacity: 1;
          fill: var(--theme-win);
        }
      }

      &_egal {
        & + svg {
          opacity: 1;
          fill: var(--theme-egal);
        }
      }

      &_lose {
        & + svg {
          opacity: 1;
          fill: var(--theme-lose);
        }
      }
    }
  }

  &__img {
    width: 200px;
    animation: appear 300ms ease-in-out forwards;
    opacity: 0;
  }

  &__title {
    margin: 15px 0 20px;
    @include fontSize(h1);
    animation: appear 300ms ease-in-out forwards;
    animation-delay: 150ms;
    opacity: 0;
  }

  &__text {
    margin-bottom: 40px;
    animation: appear 300ms ease-in-out forwards;
    animation-delay: 300ms;
    opacity: 0;
    text-align: center;

    &__content {
      opacity: .7;
      text-align: center;
    }
  }

  &__link {
    animation: appear 300ms ease-in-out forwards;
    animation-delay: 450ms;
    opacity: 0;
  }

  &__waiting {
    @include fontSize(h2);
    font-weight: 700;
    padding: 10px 0;
  }
}

@keyframes appear {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}