@import '../../../assets/styles/utils/mixins';

.code {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: center;
  height: 100vh;
  height: 100svh;
  overflow: hidden;

  &__content {
    margin: auto;
  }

  &__title {
    margin: 30px 0;
  }

  &__copy {
    margin-bottom: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__waiting {
      color: var(--theme-color-reverse);
      opacity: 0;
      transform: rotate(-1.2deg);
      margin-top: 5px;
    }

    &__text {
      display: inline-block;
      background: var(--theme-color-reverse);
      @include fontSize(h2);
      color: var(--theme-color);
      border-radius: 50px;
      padding: 8px 30px 5px;
      font-size: 20px;
      line-height: initial;
      position: relative;
      z-index: 2;
    }

    &__link {
      margin-top: -10px;
      padding: 20px 15px 30px;
      color: var(--theme-background);
      background: var(--theme-link);
      transition: box-shadow .3s ease-in-out;
      display: block;
      transform: rotate(-1.2deg);
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        background: var(--theme-background-dark);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translateX(100%);
        transition: transform .3s ease-in-out;
      }

      p {
        @include fontSize(h1);
        font-weight: 500;
      }
    }

    &.waiting {
      animation: waiting 3.5s ease-in-out infinite;

      .code__copy {
        &__waiting {
          opacity: .7;
        }

        &__link {
          &:before {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__or {
    margin: 0 auto;
    @include fontSize(h1);
  }

  &__share {
    margin: 30px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--theme-link);
    color: var(--theme-color-reverse);
    transform: rotate(1.9deg);
    width: 100px;
    height: 100px;
    cursor: pointer;

    &__icon {
      font-size: 45px;
    }
  }

  &__picture {
    margin-top: auto;
    max-height: 300px;
  }
}

@include breakpoint(medium) {
  .code {
    &__title {
      margin: 80px 0 50px;
    }

    &__copy {
      &__link {
        padding: 20px 60px 30px;
        display: inline-block;

        &:hover {
          &:before {
            transform: translateX(0);
          }
        }
      }

      &__waiting {
        margin-top: 0;
      }
    }

    &__share,
    &__or {
      display: none;
    }

    &__picture {
      max-width: 90%;
      flex: none;
      height: auto;
      max-height: 40vh;
    }
  }
}

@keyframes waiting {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.92);
  }
  100% {
    transform: scale(1);
  }
}