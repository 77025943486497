@import '../../assets/styles/utils/mixins';

.disconnect {
  background: var(--theme-color);
  color: var(--theme-color-reverse);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: center;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  padding: 40px 0;
  @include safeArea(40px, 40px);

  &__content {
    margin: auto 0 0;
  }

  &__img {
    margin: 30px auto auto;
    max-width: 90%;
  }

  &__link {
    padding-top: 30px;
  }
}

@include breakpoint(medium) {
  .disconnect {
    padding: 40px 0;

    &__img {
      margin-top: 30px;
      margin-bottom: auto;
      max-width: 40%;
    }
  }
}