@import '../../assets/styles/utils/mixins';

.choices {
  color: var(--theme-link);
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &_disabled {
    .choices__item {
      opacity: .3;
      pointer-events: none;

      &_active {
        opacity: 1;
        fill: var(--theme-color-reverse);

        &:before {
          transform: scale(1);
        }
      }
    }
  }

  &_blured {
    .choices {
      &__item {
        &:first-child {
          order: 3;
        }

        &:nth-child(2) {
          order: 1;
        }

        &:nth-child(3) {
          order: 1;
        }
      }

      &__img {
        filter: blur(8px);
      }
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 30px;
    opacity: .7;

    &_none {
      display: none;
    }

    &_blured {
      filter: blur(3px);
    }
  }

  &__item {
    width: 75px;
    height: 98px;
    transform: matrix(1, -0.07, 0.07, 1, 0, 0);
    border: 3px solid var(--theme-link);
    border-radius: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    fill: var(--theme-link);
    margin: 0 5px;

    &:before {
      content: '';
      background: var(--theme-link);
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      position: absolute;
      border: 3px solid var(--theme-link);
      border-radius: 600px;
      transform: scale(0);
      transition: transform .3s ease-in-out;
    }

    &_active {
      opacity: 1;
      fill: var(--theme-color-reverse);
      pointer-events: none;

      &:before {
        transform: scale(1);
      }
    }
  }

  &__img {
    width: 45px;
    position: relative;
    z-index: 2;
  }
}

@include breakpoint(medium) {
  .choices {
    &__item {
      width: 82px;
      height: 105px;

      &:hover {
        fill: var(--theme-color-reverse);

        &:before {
          transform: scale(1);
        }
      }
    }

    &__img {
      width: 50px;
    }
  }
}