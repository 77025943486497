* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: var(--theme-background);
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 400;
  color: var(--theme-color);
  @include fontSize();
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.custom-container {
  @extend .container-fluid;
  max-width: calc(100% - 30px);
}

.h-100 {
  height: 100%;
}

h1 {
  @include fontSize(h1);
  font-weight: 500;
}

h2 {
  @include fontSize(h2);
  font-weight: 400;
}

h3 {
  @include fontSize(big);
  font-weight: 400;
}

p {
  @include fontSize();
  line-height: 1.5em;
}


@include breakpoint(medium) {
  .custom-container {
    max-width: calc(100% - 60px);
  }
}

@include breakpoint(large) {
  .custom-container {
    max-width: calc(100% - 200px);
  }
}
