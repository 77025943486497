$breakpointList: (
        'xsmall': 576px,
        'small': 768px,
        'medium': 992px,
        'large': 1200px,
        'xlarge': 1600px,
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpointList, $breakpoint) {
    @media (min-width: map-get($breakpointList, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpointList)}.";
  }
}

@mixin fontSize($size: '') {
  @if ($size == 'h1') {
    line-height: initial;
    font-size: 28px;

    @include breakpoint(small) {
      font-size: var(--theme-fontH1);
    }
  }
  @else if ($size == 'h2') {
    font-size: 20px;
    line-height: initial;

    @include breakpoint(small) {
      font-size: var(--theme-fontH2);
    }
  }
  @else if ($size == 'big') {
    font-size: var(--theme-fontBig);
    line-height: initial;
  }
  @else if ($size == 'cgv') {
    font-size: var(--theme-fontCgv);
    line-height: 1.5em;
  }
  @else {
    font-size: var(--theme-fontNormal);
    line-height: 1.5em;
  }
}

@mixin resetSvg() {
  font-size: 0;
  line-height: 0;
}

@mixin overwriteIcon() {
  &[class*='material-symbols-outlined'] {
    @content;
  }
}

@mixin safeArea($top: 0px, $bottom: 0px) {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  padding-top: calc($top + (var(--safe-area-inset-top)));
  padding-bottom: calc($bottom + (var(--safe-area-inset-bottom)));
}