@import '../../assets/styles/utils/mixins';

.link {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 10px;
  @include fontSize(h2);

  &_noSize {
    font-size: inherit;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  &__icon {
    margin-right: 7px;
    display: inline-block;
    transition: transform 0.7s;
    transform: translate3d(0, 0px, 0);

    @include overwriteIcon {
      font-size: 30px;
    }
  }

  &__arrow {
    margin-left: 30px;
    font-size: 34px;
  }

  &__graphic {
    svg {
      position: absolute;
      left: 0;
      pointer-events: none;
      fill: none;
      top: 0;
      stroke-width: 3px;
      transition: transform 0.7s;
      transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    }
  }
}


@include breakpoint(medium) {
  .link {
    &:hover {
      .link {
        &__graphic {
          svg {
            transform: translate3d(-66.6%, 0, 0);
          }
        }

        &__icon {
          animation: iconLink 5s ease-in-out infinite;
        }
      }
    }

    &__icon {
      @include overwriteIcon  {
        font-size: 40px;
      }
    }

    &__graphic {
      svg {
        stroke-width: 4px;
      }
    }
  }
}

@keyframes iconLink {
  0% {
    transform: translate3d(0, 0, 0);
  }
  20% {
    transform: translate3d(0, -2px, 0);
  }
  40% {
    transform: translate3d(0, 4px, 0);
  }
  60% {
    transform: translate3d(0, -2px, 0);
  }
  80% {
    transform: translate3d(0, 2px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}