@import '../../assets/styles/utils/mixins';
@import '../../assets/styles/utils/keyframes';

.home {
  display: flex;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 10px 15px 60px;
  @include safeArea(10px, 60px);

  &__copyright {
    font-size: 14px;
    padding-bottom: 15px;

    &__img {
      margin: 0 0 -2px 5px;
    }
  }

  &__picture {
    margin: auto;
    max-height: 80%;
  }

  &__link {
    margin-top: 30px;
    animation: appear .3s ease-in-out forwards;
    animation-delay: 2000ms;
    transition: opacity .3s ease-in-out;
    opacity: 0;
  }
}

@include breakpoint(medium) {
  .home {
    padding: 10px 80px 80px;
  }
}
