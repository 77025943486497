@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v61/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCdHOejbd5zrDAt.woff2) format('woff2');
}

@font-face {
  font-family: "Stacion";
  font-display: swap;
  src: url('../../fonts/stacion_regular.woff2') format("woff2"),
  url("../../fonts/stacion_regular.woff") format("woff");
}

.font-stacion {
  font-family: 'Stacion', sans-serif;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}