@import 'src/assets/styles/utils/mixins';

.online {
  background: var(--theme-color);
  color: var(--theme-color-reverse);
  height: 100vh;
  height: 100svh;
  overflow: auto;

  &__back {
    --safe-area-inset-top: env(safe-area-inset-top);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    top: calc(15px + (var(--safe-area-inset-top)));
    left: 0;
    z-index: 7;
    background: var(--theme-color-light);
    height: 40px;
    width: 60px;
    transform: translateX(-20px);
    transition: transform .3s ease-in-out;
    border-radius: 0 20px 20px 0;
    align-items: center;
    padding: 0 10px;

    &:hover {
      transform: translateX(0);
    }
  }
}
