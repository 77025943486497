@import '../../assets/styles/utils/mixins';
@import '../../assets/styles/utils/keyframes';

.presentation {
  display: flex;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @include safeArea(30px, 30px);
  text-align: center;

  &__picture {
    margin: auto auto 0;
    max-height: 80%;
    animation: appear .3s ease-in-out forwards;
    animation-delay: 0ms;
    opacity: 0;
  }

  &__title {
    margin: 50px 0 auto;
    animation: appear .3s ease-in-out forwards;
    animation-delay: 500ms;
    opacity: 0;
  }

  &__link {
    margin: 30px auto 30px;
    animation: appear .3s ease-in-out forwards;
    animation-delay: 1000ms;
    opacity: 0;
  }
}