@keyframes counter {
  0% {
    opacity: 0;
    transform: scale(9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes appearLeft {
  0% {
    opacity: 0;
    transform: translateY(40px) scale(3);
  }
  100% {
    opacity: 1;
    transform: translateY(40px) scale(1);
  }
}

@keyframes appearRight {
  0% {
    opacity: 0;
    transform: translateY(-40px) scale(3);
  }
  100% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
}

@keyframes appearLeftDefault {
  0% {
    opacity: 0;
    transform: scale(3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes appearRightDefault {
  0% {
    opacity: 0;
    transform: scale(3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translate(0) rotate(0deg);
  }
  5% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}