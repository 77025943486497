@import '../../../assets/styles/utils/keyframes';
@import '../../../assets/styles/utils/mixins';
@import 'src/pages/game/game.module';

.game {
  &__background {
    fill: var(--theme-color-light);
    transition: fill .3s ease-in-out;
    opacity: 1;
    bottom: 0;
    top: 50%;
  }

  &__footer {
    &_waiting {
      opacity: .3;
      pointer-events: none;
    }
  }
}

.sound {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 30px;
  left: 50px;
  z-index: 2;
  @include fontSize(cgv);

  &__content {
    margin-left: 10px;
    flex: 1;
  }

  &__text {
    font-size: inherit;
  }

  &__img {
    flex: none;
    width: 70px;
  }

  &__copyright {
    font-size: 9px;
    font-style: italic;
    margin: 2px 0 0;
    opacity: .3;
  }
}

.waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;

  &__img {
    height: 180px;
    border-radius: 50%;
    margin-bottom: 25px;
  }

  &__title {
    text-align: center;
    @include fontSize(h1);
  }
}