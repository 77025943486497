@import '../../assets/styles/utils/mixins';

.extra {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--theme-link);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  @include safeArea(30px, 30px);
  opacity: 0;
  animation: appear 300ms ease-in-out forwards;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;

  &_malus {
    background: var(--theme-background-dark);
  }

  &__img {
    display: block;
    margin: auto;
    text-align: center;
    animation: textAppear 300ms ease forwards;
    animation-delay: 400ms;
    opacity: 0;
  }

  &__title {
    @include fontSize(h1);
    font-weight: 500;
    text-align: center;
    margin: 15px 0 25px;
    animation: textAppear 300ms ease forwards;
    animation-delay: 700ms;
    opacity: 0;
  }

  &__link {
    margin-top: auto;
    padding-top: 20px;
    animation: textAppear 300ms ease forwards;
    animation-delay: 1300ms;
    opacity: 0;
  }
}

.content {
  text-align: center;
  animation: textAppear 300ms ease forwards;
  animation-delay: 1000ms;
  opacity: 0;

  &__picture {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 25px;
    padding: 8px 35px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transform: matrix(-0.07, 1, -1, -0.07, 0, 0) rotate(94deg);
      border-radius: 600px;
      background: var(--theme-background);
    }

    &__img {
      position: relative;
      z-index: 1;
      width: 50px;
      fill: var(--theme-color);
    }
  }

  &__name {
    @include fontSize(big);
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__text {
    opacity: .7;
  }
}

@include breakpoint(medium) {
  .extra {
    padding: 80px 30px;

    &__title {
      margin: 20px 0 40px;
    }
  }

  .content {
    &__picture {
      padding: 20px 35px;

      &__img {
        width: 90px;
      }
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes textAppear {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}